/* App.css và News.css hợp nhất */

/* Đặt màu nền cho toàn bộ trang */
body {
  background-color: #121212;  /* Màu nền đen */
  color: #FFFFFF;  /* Màu chữ trắng */
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Cấu hình cho thanh nav và menu hamburger */
nav {
  background-color: #333; /* Màu nền cho thanh nav */
  color: #fff;
  padding: 10px 20px;
  position: relative;
}

.hamburger {
  display: none; /* Ẩn trên màn hình lớn */
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
}

.nav-links {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 15px;
  display: block;
}

.nav-links a:hover {
  background-color: #555;
}

/* Hiển thị menu hamburger và kiểu dáng cho các màn hình nhỏ */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Điều chỉnh vị trí menu */
    right: 0;
    background-color: #333;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Thêm hiệu ứng bóng cho menu */
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 0;
  }
}

/* Thiết kế cho phần container */
.container {
  background-color: #1E1E1E; /* Màu nền xám đậm cho container */
  padding: 20px;
  margin: 20px auto;
  border-radius: 8px;
  max-width: 1200px;
  width: 100%; /* Đảm bảo container không bị nhỏ hơn kích thước viewport */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Thêm hiệu ứng bóng cho container */
  box-sizing: border-box; /* Đảm bảo padding không làm tăng kích thước */
}

/* Định dạng cho lưới coin */
.coin-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Chia thành 2 cột */
  gap: 20px; /* Khoảng cách giữa các cột và hàng */
}

/* Định dạng cho từng mục coin */
.coin-item {
  background-color: #2E2E2E; /* Màu nền cho từng mục coin */
  padding: 15px;
  border-radius: 8px;
  width: 100%; /* Chiều rộng 100% của cột */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Thêm hiệu ứng bóng cho từng mục */
  box-sizing: border-box; /* Đảm bảo padding và border không làm thay đổi kích thước */
}

.coin-item h3 {
  font-size: 1.5em;
  color: #FFD700; /* Màu vàng nổi bật cho tiêu đề */
}

.coin-item p {
  font-size: 1em;
  color: #CCCCCC; /* Màu xám nhạt cho đoạn văn */
}

/* Home page styling */
.container h1 {
  font-size: 2.5em;
  color: #FFD700; /* Màu vàng nổi bật cho tiêu đề */
  margin: 0; /* Loại bỏ margin mặc định */
}

.container h2 {
  font-size: 1.8em;
  color: #FFD700; /* Màu vàng cho tiêu đề của coin */
}

.container p {
  font-size: 1.2em;
  color: #CCCCCC; /* Màu xám nhạt cho đoạn văn */
  margin: 0; /* Loại bỏ margin mặc định */
}
