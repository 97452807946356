/* Cấu hình kiểu dáng cho trò chơi Tic Tac Toe */
.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.board-row {
  display: flex;
}

.square {
  width: 60px;
  height: 60px;
  background: #fff;
  border: 1px solid #999;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square:focus {
  outline: none;
}

.status {
  margin-bottom: 10px;
  font-size: 18px;
}

.reset-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #0056b3;
}
